@use '@skyscanner/backpack-web/unstable__bpk-mixins/borders';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../mixins';

.CustomNav {
  padding-top: tokens.bpk-spacing-md();
  padding-bottom: tokens.bpk-spacing-base();
  background-color: tokens.$bpk-surface-hero-day;

  *:focus-visible {
    outline-color: tokens.$bpk-color-white;
  }

  .Grid {
    @include mixins.custom-pages-grid;

    .MobileMenuToggle {
      display: none;

      @include breakpoints.bpk-breakpoint-small-tablet {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    .MobileMenu {
      display: none;
      background-color: tokens.$bpk-surface-hero-day;

      @include breakpoints.bpk-breakpoint-small-tablet {
        &--open {
          display: block;
          padding-top: tokens.bpk-spacing-base();
          padding-bottom: tokens.bpk-spacing-lg();
        }
      }

      .SingleMenuItem {
        @include borders.bpk-border-bottom-sm(tokens.$bpk-line-on-dark-day);

        a {
          display: block;
          width: 100%;
          padding: tokens.bpk-spacing-base() 0;
        }
      }

      .SubMenu {
        .SubMenuItem {
          a {
            display: block;
            width: 100%;
            padding: tokens.bpk-spacing-md() 0;
          }
        }
      }
    }

    .DesktopMenu {
      position: relative;
      display: flex;
      align-items: center;
      gap: tokens.bpk-spacing-xl();

      @include breakpoints.bpk-breakpoint-small-tablet {
        display: none;
      }

      .MenuItem {
        position: relative;

        a {
          display: inline-block;
          padding: (12 * tokens.$bpk-one-pixel-rem) 0;
        }

        .Chevron {
          position: absolute;
          display: inline-flex;
          height: 100%;
          margin-left: tokens.bpk-spacing-sm();
          flex-wrap: wrap;
          align-content: center;
          pointer-events: none;

          svg {
            visibility: hidden;
            fill: tokens.$bpk-color-white;
          }

          &:focus-within {
            svg {
              visibility: visible;
            }
          }
        }

        .SubMenu {
          position: absolute;
          display: none;
          z-index: tokens.$bpk-zindex-popover;
        }

        .SubMenuOpen {
          display: block;
        }

        .SubMenuInner {
          display: flex;
          padding: tokens.bpk-spacing-md() tokens.bpk-spacing-base();
          padding-bottom: tokens.bpk-spacing-base();
          flex-direction: column;
          background-color: tokens.$bpk-surface-hero-day;
          box-shadow: tokens.$bpk-box-shadow-lg;

          .SubMenuItem {
            min-width: tokens.bpk-spacing-base() * 10;

            a {
              display: block;
              width: 100%;
              padding: tokens.bpk-spacing-md() 0;
            }
          }
        }
      }
    }
  }
}
